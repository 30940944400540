.report-container {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 1rem;
}

.report-container div {
  flex: 1;
  text-align: center;
}

.report-container div:first-of-type {
  border-right: 1px solid #e0e0e0;
}

.report {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 5px 0;
}

.report.incomes {
  color: #00ff4c;
}

.report.expenses {
  color: #ff0000;
}