@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;
}

body {
  background: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
}

.container {
  margin: 30px auto;
  width: 350px;
}

.title {
  font-size: 1.7em;
  text-align: center;
  color: #028dff;
}

.navbar {
  background: #fff;
}

.navbar ul {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.navbar ul li {
  margin: 0;
  flex: 1;
  text-align: center;
  list-style: none;
  
}

.navbar ul li:first-of-type {
  border-right: 1px solid #000;
}

.navbar ul li a {
  color: #000;
  text-decoration: none;
}

.navbar ul li a:hover {
  color: #555555;
}