.item_list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 3em;
}

.item_list li {
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 1.5rem 2rem;
  margin: 1rem 0;
  color: #333;
  display: flex;
  justify-content: space-between;
  position: relative;
}